import { defineNuxtPlugin } from '#app/nuxt'
import { LazyDemoCollapseNavigationFooter, LazyDemoCollapseNavigationHeader, LazyDemoIconText, LazyDemoToolbarAccountMenu, LazyTairoLogo, LazyTairoLogoText, LazyTairoToaster, LazyTairoTocAnchor, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["DemoCollapseNavigationFooter", LazyDemoCollapseNavigationFooter],
["DemoCollapseNavigationHeader", LazyDemoCollapseNavigationHeader],
["DemoIconText", LazyDemoIconText],
["DemoToolbarAccountMenu", LazyDemoToolbarAccountMenu],
["TairoLogo", LazyTairoLogo],
["TairoLogoText", LazyTairoLogoText],
["TairoToaster", LazyTairoToaster],
["TairoTocAnchor", LazyTairoTocAnchor],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
