/**
 * This file is used to configure the app
 *
 * If you have the "Cannot find name 'defineAppConfig'.ts(2304)" error
 * update the root tsconfig.json file to include the following:
 *
 *  "extends": "./.app/.nuxt/tsconfig.json"
 *
 */

export default defineAppConfig({
  tairo: {
    title: 'Catálogo Personalização',
    collapse: {
      toolbar: {
        enabled: true,
        showTitle: true,
        showNavBurger: true,
        tools: [],
      },
      circularMenu: {
        enabled: false,
        tools: [],
      },
      navigation: {
        enabled: true,
        header: {
          component: 'DemoCollapseNavigationHeader',
          resolve: true,
        },
        footer: {
          component: 'DemoCollapseNavigationFooter',
          resolve: true,
        },
        items: [
          {
            name: 'Catálogo',
            icon: { name: 'material-symbols:dashboard', class: 'w-5 h-5' },
            to: '/dashboard',
          },
          {
            name: 'Cadastrar Arte',
            icon: { name: 'gridicons:create', class: 'w-5 h-5' },
            to: '/dashboard/arte',
          }
        ],
      },
    },
    panels: [],
    error: {
      logo: {
        component: 'img',
        props: {
          src: '/img/illustrations/system/404-1.svg',
          class: 'relative z-20 w-full max-w-lg mx-auto',
        },
      },
    },
  },
})
