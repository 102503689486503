import revive_payload_client_YEg5mLEx81 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_axios@1.6.8_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4._bc7pizinnshtagerxlfliagi4a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_aHny4lg2WZ from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_axios@1.6.8_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4._bc7pizinnshtagerxlfliagi4a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_DNZChWeL1O from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_axios@1.6.8_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4._bc7pizinnshtagerxlfliagi4a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_RKmKPp3er7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_axios@1.6.8_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4._bc7pizinnshtagerxlfliagi4a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_0IG6KE06qm from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_axios@1.6.8_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4._bc7pizinnshtagerxlfliagi4a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.app/.nuxt/components.plugin.mjs";
import prefetch_client_I8f6peLzRm from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_axios@1.6.8_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4._bc7pizinnshtagerxlfliagi4a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_exNVq2O2Nn from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_mYRZapb4zS from "/vercel/path0/node_modules/.pnpm/@cssninja+nuxt-toaster@0.3.12_vue@3.4.22/node_modules/@cssninja/nuxt-toaster/dist/runtime/plugin.mjs";
import chunk_reload_client_5LTXRIS4fw from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.2_axios@1.6.8_eslint@8.57.0_floating-vue@5.2.2_typescript@5.4._bc7pizinnshtagerxlfliagi4a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import directives_yBPzJQtdia from "/vercel/path0/layers/tairo/plugins/directives.ts";
import mask_Tk3SUcMqzt from "/vercel/path0/.app/plugins/mask.ts";
import image_expander_X4bc5TCd7Y from "/vercel/path0/.app/plugins/image-expander.ts";
export default [
  revive_payload_client_YEg5mLEx81,
  unhead_aHny4lg2WZ,
  router_DNZChWeL1O,
  payload_client_RKmKPp3er7,
  check_outdated_build_client_0IG6KE06qm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_I8f6peLzRm,
  plugin_client_exNVq2O2Nn,
  plugin_mYRZapb4zS,
  chunk_reload_client_5LTXRIS4fw,
  directives_yBPzJQtdia,
  mask_Tk3SUcMqzt,
  image_expander_X4bc5TCd7Y
]