import { default as arteCw1TMmxxZEMeta } from "/vercel/path0/.app/pages/dashboard/arte.vue?macro=true";
import { default as indexYSX8kKfq6GMeta } from "/vercel/path0/.app/pages/dashboard/index.vue?macro=true";
import { default as indexJX2dp37VJQMeta } from "/vercel/path0/.app/pages/index.vue?macro=true";
export default [
  {
    name: arteCw1TMmxxZEMeta?.name ?? "dashboard-arte",
    path: arteCw1TMmxxZEMeta?.path ?? "/dashboard/arte",
    meta: arteCw1TMmxxZEMeta || {},
    alias: arteCw1TMmxxZEMeta?.alias || [],
    redirect: arteCw1TMmxxZEMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/dashboard/arte.vue").then(m => m.default || m)
  },
  {
    name: indexYSX8kKfq6GMeta?.name ?? "dashboard",
    path: indexYSX8kKfq6GMeta?.path ?? "/dashboard",
    meta: indexYSX8kKfq6GMeta || {},
    alias: indexYSX8kKfq6GMeta?.alias || [],
    redirect: indexYSX8kKfq6GMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexJX2dp37VJQMeta?.name ?? "index",
    path: indexJX2dp37VJQMeta?.path ?? "/",
    meta: indexJX2dp37VJQMeta || {},
    alias: indexJX2dp37VJQMeta?.alias || [],
    redirect: indexJX2dp37VJQMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/index.vue").then(m => m.default || m)
  }
]