<script setup lang="ts">
const route = useRoute()
const app = useAppConfig()

/**
 * Global head configuration
 * @see https://nuxt.com/docs/getting-started/seo-meta
 */
useHead({
  title: () => route.meta?.title ?? '',
  titleTemplate: (titleChunk) => {
    return titleChunk
      ? `${titleChunk} - ${app.tairo?.title}`
      : `${app.tairo?.title}`
  },
  htmlAttrs: {
    lang: 'pt-br',
    dir: 'ltr',
  },
  link: [
    {
      rel: 'icon',
      type: 'image/png',
      href: '/img/favicon.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/apple-touch-icon.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon-32x32.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon-16x16.png',
    },
    {
      rel: 'manifest',
      href: '/site.webmanifest',
    }
  ],
})
</script>

<template>
  <div>
    <!--
      Global app search modal
      @see .demo/components/DemoAppSearch.vue
    -->
    <!-- <DemoAppSearch /> -->
    <!--
      Global app layout switcher
      @see .demo/components/DemoAppLayoutSwitcher.vue
    -->
    <!-- <DemoAppLayoutSwitcher /> -->

    <!--
      Vue Axe Popup
      @see .demo/plugins/vue-axe.client.ts
    -->
    <!-- <VueAxePopup /> -->

    <NuxtLayout>
      <!-- <NuxtLoadingIndicator color="rgb(var(--color-primary-500))" /> -->
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
